import GlobalLayout from "components/page/GlobalLayout";
import SensorSpecsPage from "components/sensors/SensorSpecsPage";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function M1() {
  const renderContent = data => {
    const sensor = {
      title: "M1",
      imageHeader: data.m1Header,
      imageHeaderMobile: data.m1HeaderMobile,
      image: data.m1Dims,
      objectPosition: "center",
      description:
        "Obtain visibility without cameras. Acquire insights into camera-less locations with real-time motion sensing capabilities.",
      pdf: "/files/specs/M1.pdf",
      column1: [
        {
          title: "Dimensions",
          text: "59mm x 54mm x 24mm (2.3in x 2.1in x 0.94in)",
        },
        {
          title: "Range",
          text: "Up to 100m (300ft)",
        },
        {
          title: "Battery Type",
          text: "1x CR123, replaceable",
        },
        {
          title: "Battery Life",
          text: "2 years",
        },
        {
          title: "Motion Capabilities",
          text: "Passive infrared and light-level detection",
        },
      ],
      column2: [
        {
          title: "Sensor Coverage",
          text:
            "5m (15 feet) with 90° FoV (covers up to 200 sq.ft. in ideal conditions)",
        },
        {
          title: "Mounting",
          text: "Two-sided tape or screws (included)",
        },
        {
          title: "Outdoor Use",
          text:
            "Used in a covered location; resistant to brief incidental splashes",
        },
        {
          title: "2-Year Warranty Included",
        },
      ],
    };

    return (
      <GlobalLayout color="transparent" dark>
        <Helmet>
          <title>Rhombus M1 Motion Sensor - Measure Building Occupancy</title>
          <meta
            name="description"
            content="Enterprise-grade motion sensors for commercial businesses. Learn about the M1 Motion Sensor and how it can provide insight into space occupancy."
          />
          <meta name="thumbnail" content="https://rhombus.com/img/m1-seo.png" />
        </Helmet>
        <SensorSpecsPage sensor={sensor} />
      </GlobalLayout>
    );
  };

  const GET_IMAGES = graphql`
    query {
      m1Header: file(
        relativePath: { eq: "components/sensors/img/m1-header-2000-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      m1HeaderMobile: file(
        relativePath: {
          eq: "components/sensors/img/m1-header-mobile-500-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      m1Dims: file(
        relativePath: { eq: "components/sensors/img/m1-dims-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
